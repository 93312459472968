import React, { useState, useEffect } from 'react'
import { Button, Card, Segment, Grid, Image, Loader, Dimmer, Header } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { Field, Form, Formik } from 'formik'
import Input from 'components/InputForm'
import { useDispatch, connect } from 'react-redux'


import LogoImg from '../../images/logo1024.jpg'
import { showErrorMessage } from 'utils/messages'
import { resetPassword, resetPasswordUser, recoveryPassword, verifyToken, verifyTokenUser } from 'store/actions/app'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min'
function validate(email) {}

const ChangePassword = (props) => {
	const [sending, setSending] = useState(false)
	const [loading, setLoading] = useState(true)
	const [loadingText, setLoadingText] = useState('Cargando');
	const [intervalo, setIntervalo] = useState(null)
	const [isValid, setIsValid] = useState(false);
	const [sent, setSent] = useState(false)
	const [isClient, setIsClient] = useState(false);

	const dispatch = useDispatch()
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const token = queryParams.get('token').split('?typeUser')[0];
	const typeUser = queryParams.get('token').split('?typeUser=')[1];

	useEffect(() => {
		setIntervalo(
			setInterval(() => {
				setLoadingText((loadingText) => {
					const parts = loadingText.split('.').length
					if (parts === 1) {
						setLoadingText('Cargando.')
					} else if (parts === 2) {
						setLoadingText('Cargando..')
					} else if (parts === 3) {
						setLoadingText('Cargando...')
					} else if (parts === 4) {
						setLoadingText('Cargando')
					}
				})
			}, 300)
		)
		// setToken(queryParams.get('token'));
		const fetchdata = async() =>{//AQUI
			// let validCoU = null;
			// validCoU = await dispatch(verifyToken(token));
			// setIsClient(validCoU);
			// console.log("VALIDCU: "+validCoU);
			// if(!validCoU){
			// 	validCoU = await dispatch(verifyTokenUser(token));
			// 	console.log("VALIDCU: "+validCoU);
			// }
			if(typeUser == 0){
				setIsValid(await dispatch(verifyToken(token)))
			}else if(typeUser == 1){
				setIsValid(await dispatch(verifyTokenUser(token)))
			}
			// setIsValid(await dispatch(verifyToken(token)))//Verificando token
			setLoading(false);
		}
		fetchdata();
	}, [])

	useEffect(() => {
		if(!loading) {
			clearInterval(intervalo);
		}
	}, [loading]);

	const onSubmit = async (values, { setSubmitting }) => {
		//Evento para mandar API
		const password = values?.password
		const passwordRepeat = values?.passwordRepeat
		if(password === passwordRepeat){
			setSending(true)
			//Realizar peticion
			if(typeUser == 0){
				await dispatch(resetPassword({token, password}))
				setSent(true);
			}else if(typeUser == 1){
				await dispatch(resetPasswordUser({token, password}))
				setSent(true);
			}
			
			setSending(false);
		}else{
			return showErrorMessage({error: 'La contraseñas no coinciden'})
		}
	}

	// if (loading) {
	// 	return (
	// 		<Dimmer active>
	// 			<Loader inverted>{loadingText}</Loader>
	// 		</Dimmer>
	// 	)
	// }

	return (
		!sent?(
		<>
		{!loading ? (<div
			style={{
				width: '100wh',
				height: '100vh',
				justifyContent: 'center',
				alignItems: 'center',
				padding: '20px',
			}}
			className='login-background'
		>
			<div
				style={{
					display: 'flex',
					height: '20vh',
					position: 'fixed',
					top: 0,
					left: 0,
				}}
			></div>
			<Grid centered>
				<Grid.Row>
					<Grid.Column computer={6} tablet={8} mobile={16}>
						<Segment>
							<Grid stackable>
								<Grid.Row
									style={{
										alignItems: 'center',
										justifyContent: 'center',
									}}
									textAlign='center'
								>
									<Image
										src={process.env.REACT_APP_LOGO_LOGIN_URL ?? LogoImg}
										size='medium'
									/>
								</Grid.Row>
								<Grid.Row
									style={{
										alignItems: 'center',
										justifyContent: 'center',
									}}
									textAlign='center'
								>
									{isValid ? (
										<Formik initialValues={{}} onSubmit={onSubmit}>
											{({ isSubmitting }) => (
												<Form className='ui form'>
													<Card>
														<Card.Content>
															<Card.Header textAlign='center'>
																Sitio del Cliente
															</Card.Header>
															<Card.Description>
																Recuperación de contraseña
															</Card.Description>
														</Card.Content>
														<Card.Content>
															<Field
																type='password'
																name='password'
																component={Input}
																label='Nueva contraseña'
															/>
															<Field
																type='password'
																name='passwordRepeat'
																component={Input}
																label='Repita nueva contraseña'
															/>
															<Button
																style={{
																	margin: '1rem 0',
																	width: '100%',
																}}
																className='general-color'
																type='submit'
																disabled={sending}
																content={
																	<>
																		{sending ? (
																			<Loader
																				size='mini'
																				active
																				inline='centered'
																			>
																				Enviando
																			</Loader>
																		) : (
																			'Enviar'
																		)}
																	</>
																}
															/>
														</Card.Content>
													</Card>
												</Form>
											)}
										</Formik>) : (
												<div  style={{ marginTop: 50, marginBottom: 50 }}>
													<Header as='h2' color='red'>Token expirado</Header>
												</div>
										)}
								</Grid.Row>
							</Grid>
						</Segment>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</div>) : (
			<Dimmer active>
				<Loader inverted>{loadingText}</Loader>
			</Dimmer>
		)}
		</>):(
			typeUser == 0 ? <Redirect to='/login_client' /> :<Redirect to='/login' />
		)
	)
}
const mapDispatchToProps = (dispatch) => ({
	recoveryPassword: (email) => dispatch(recoveryPassword(email)),
	// verifyPassword: (token) => dispatch(verifyToken(token))
})

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)
