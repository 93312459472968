export default {
	en: {
		client: 'Client',
		company: 'Company',
		email: 'Email',
		password: 'Password',
		email2: 'Email or phone',
		logIn: 'Log In',
		logOut: 'Log Out',
		forgotPass: 'Forgot your password?',
		noAccount: "Don't have an account?",
		createAccount: 'Create one here',
		selectLanguage: 'Select Language',
		clientAccount: 'Create Client Account',
		businessAccount: 'Create Business Account',
		allSet: 'I already have an account',
		recoverPass: 'Recover password',
		registration: 'Registration email',
		send: 'Send',
		recordClient: 'Client registration',
		recordCompany: 'Company Registration',
		mandatoryField: 'Mandatory Field',
		nameM: 'Name *',
		phoneM: 'Phone *',
		birthday: 'Birth Date',
		minAge: 'Client must be at least 18 years old',
		emailM: 'Email *',
		passwordM: 'Password *',
		confirmPassM: 'Confirm Password *',
		termsAndConditions: 'Accept terms and conditions -',
		see: 'See',
		acceptConditions: 'Terms and conditions not accepted',
		accountCreated: 'Already have an account?',
		companyNameM: 'Company Name *',
		atentionC: 'Counter or Bar Attention (without waiters)',
		atentionR: 'Restaurant Attention (with waiters)',
		atentionFC: 'Food Center Attention (with waiters)',
		atentionE: 'Event Attention (with waiters)',
		atentionHT: 'Hospitality Attention (with waiters)',
		atentionH: 'Hospital Attention (with waiters)',
		atentionCM: 'Casino Machine Attention (with waiters)',
		numRooms: 'No. of rooms',
		numMachines: 'Number of machines',
		numStations: 'Number of stations',
		numAssistants: 'Number of waiters',
		anniversary: 'Anniversary',
		ok: 'OK',
		selectMoney: 'Select Currency Unit',
		registrateComp: 'Register Company',
		invalidPass: 'Password does not match',
		invalidPhone: 'Invalid phone: Must contain 10 digits',
		invalidEmail: 'Invalid email: Incorrect format',
		registrate: 'Register',
		cancel: 'Cancel',
		atteType: 'Select the type of care',
		justSeeMenu: 'Just view the menu',
		menu: 'Menu',
		sendToAssistants: 'Send orders to the assistant',
		sendToKitchen: 'Send orders directly to the kitchen',
		accessType: 'Select the type of access for clients',
		close: 'Close',
		terms_condi_ruf: 'Terms and Conditions of Ruf',
		responsability: 'Disclaimer:',
		responsabilityTxt1: `The terms 'we' or 'our' refer to Ruf. Please note that our Terms and Conditions may change without prior notice. By using our site or placing an order, you agree to the terms below. To the extent permitted by law, we disclaim all liability for any inconvenience arising from the use of our site and system.`,
		changes: 'Order Changes:',
		changesTxt1: `If there is any change in your order, we will inform you and proceed to contact the company to issue a refund in case of cancellation. We assume no liability for any damages arising from this practice.`,
		cancelPolitics: 'Cancellation Policy:',
		cancelPoliticsTxt: `If you wish to cancel an order, please notify us immediately. Cancellation is at our discretion and that of the company where you use this platform. If the food has not yet been prepared, we will generally allow cancellation and provide a refund. Once the order has been placed, it cannot be canceled.`,
		alcohol: 'Alcohol Service:',
		alcoholTxt: `We operate as a licensed place for the sale of alcohol and comply with all local laws in this regard. We do not serve alcohol to minors under 18 years of age.`,
		payments: 'Payments:',
		paymentsTxt: `Currently, we do not accept payments, but when we do, they will be processed securely.`,
		taxes: 'Taxes:',
		taxesTxt: `Our prices include taxes, varying by company. The rate is usually 8% in border areas and 16% in inland Mexico.`,
		rights: 'Copyright:',
		rightsTxt: `All images and content on the site are copyrighted and licensed. They must not be distributed, modified, or used without our prior consent, which may be denied at our discretion. The site may be printed but not modified or used for commercial purposes.`,
		webSite: 'Website:',
		webSiteTxt: `By using our website, you agree to our terms and conditions. We strive to ensure the accuracy of the information, but we do not assume responsibility for any damages arising from incorrect information or performance issues. We use third-party content on our site.`,
		privacy: 'Privacy:',
		privacyTxt: `Your privacy is fundamental. We only request information during platform registration or if you attend companies that use our service, the app only shares your information with the companies you visit, but does not share your data with places where our platform is not used, that said, you will have consent about how this application works, you can be sure that we will take care of and protect your information. In cases where registration is done by the company, we will not use your information except to contact you or inform you of important information, but we will not share your information with anyone.`,
		contacts: 'Contacts',
		contact: 'Contact',
		offices: 'Offices',
		support: 'Support',
		sales: 'Sales',
		admin: 'Administration',
		mail: 'Emails',
		webPage: 'Website',
		productSelectedInStationFormScreen: 'The selected product will be displayed by scanning the generated QR code.',
		updateItemsMessage: (entities) => `The ${entities} were successfully updated.`,
		stalls: 'Stalls',
		stall: 'Stall',
		save: 'Save',
		cancel: 'Cancel',
		new: 'New',
		cleanFilters: 'Clean Filters',
		actions: 'Actions',
		order: 'Order',
		name: 'Name',
		phone: 'Phone',
		typeOfClientAccess: 'Type of client access',
		delete: 'Delete',
		typeOfAttention: 'Type of attention',
		placeHolder: (entity) => `Write the ${entity?.toLowerCase()} here.`,
		numAssistant: 'Number of assistants',
		attachLogo: 'Attach logo',
		attachCoverImage: 'Attach cover image',
		stations: 'Stations',
		importStations: 'Import stations',
		code: 'Code',
		edit: 'Edit',
		exportToCSV: 'Export to CSV',
		printQRs: 'Print QR codes',
		importFile: 'Import file',
		exportFile: 'Export file',
		filterByErrors: 'Filter by errors',
		verify: 'Verify',
		saveTheRecord: 'Please save the record first',
		cannotBeEmpty: `It can't be empty`,
		hideCompanyLabel: 'Hide super company',
		hideCompanyInfo: 'Hide super company in stalls menu',
		parameters: 'Parameters',
		recommendedProduct: 'Recommended product',
		sendNotificationAfter: 'Send notification after... (HH:mm)',
		notificationSettings: 'Notification settings',
		serviceRating: 'Service rating',
		recomendationInfo: 'Recommend the following products every time you buy',
		notifyClient: "Notify the client",
		accessVisible: "Visible Access Menu",
		sendToAssistant: "Send orders to assistant",
		wirelessNetworkName: 'Wireless network name',
		wirelessNetworkPassword: 'Wireless network password',
		downloadQrCode: 'Download QR Code',
		products: 'Products',
		seeProducts: 'See Products',
		companies: 'Companies',
		callAssistant: 'Call Assistant',
		statusOfOrder: 'Status of Order',
		offersAndWallet: 'Offers and Wallet',
		scanQR: 'Scan QR',
		contact: 'Contact',
		listOfCompatibleCompanies: 'List of Compatible Companies',
		hello: 'Hello',
		guest: 'Guest',
		pressTheButtonToScan: 'Press the button to scan',
		captureCode: 'Capture the QR code',
		search: 'Search',
		enterTheCode: 'Enter the code',
		companyName: 'Company name',
		stationName: 'Station name',
		rate:"Rate",
		skip:"Skip",
		bad:"Bad",
		notGood:"Not good",
		good:"Good",
		veryGood:" Very good",
		excellemt:"Excellent",
		enableAppPermissions: 'Enable app permissions in Client Settings',
		loginSettings: 'Login Settings',
		hideEmail: 'Hide email',
		notProvided: 'Not provided',
		welcomeTo: 'Welcome to'
	},
	es: {
		client: 'Cliente',
		company: 'Compañía',
		email: 'Correo Electrónico',
		password: 'Contraseña',
		email2: 'Correo electrónico ó teléfono',
		logIn: 'Iniciar Sesión',
		logOut: 'Cerrar Sesión',
		forgotPass: '¿Olvidaste tu contraseña?',
		noAccount: '¿No tienes cuenta?',
		createAccount: 'Crea una aquí',
		selectLanguage: 'Seleccionar Idioma',
		clientAccount: 'Crear cuenta de Cliente',
		businessAccount: 'Crear cuenta de Negocio',
		allSet: 'Ya tengo una cuenta',
		recoverPass: 'Recuperar contraseña',
		registration: 'Correo de registro',
		send: 'Enviar',
		recordClient: 'Registro de cliente',
		recordCompany: 'Registro de compañía',
		mandatoryField: 'Campo obligatorio',
		nameM: 'Nombre *',
		phoneM: 'Teléfono *',
		birthday: 'Fecha de nacimiento',
		minAge: 'El cliente debe tener al menos 18 años',
		emailM: 'Correo Electrónico*',
		passwordM: 'Contraseña*',
		confirmPassM: 'Repite la contraseña *',
		termsAndConditions: 'Aceptar los términos y condiciones -',
		see: 'Ver',
		acceptConditions: 'No se han aceptado los terminos y condiciones',
		accountCreated: '¿Ya tienes una cuenta?',
		companyNameM: 'Nombre de la compañía*',
		atentionC: 'Atención en Mostrador o Barra  (sin asistentes)',
		atentionR: 'Atención en Restaurante (con asistentes)',
		atentionFC: 'Atención en Plaza Comercial (con asistentes)',
		atentionE: 'Atención para eventos (con asistentes)',
		atentionHT: 'Atención para hostelería (con asistentes)',
		atentionH: 'Atención para hospitales (con asistentes)',
		atentionCM: 'Atención en Máquinas de Casino (con asistentes)',
		numRooms: 'No. de habitaciones',
		numMachines: 'Número de máquinas',
		numStations: 'Número de estaciones',
		numAssistants: 'Número de asistentes',
		anniversary: 'Aniversario',
		ok: 'OK',
		selectMoney: 'Selecciona la Unidad Monetaria',
		registrateComp: 'Registrar compañía',
		invalidPass: 'La contraseña no coincide',
		invalidPhone: 'Teléfono no válido: Debe contener 10 dígitos',
		invalidEmail: 'Correo no válido: Formato incorrecto',
		registrate: 'Registrarse',
		cancel: 'Cancelar',
		atteType: 'Selecciona el tipo de atención',
		justSeeMenu: 'Solo ver el menú',
		menu: 'Menú',
		sendToAssistants: 'Enviar órdenes para el asistente',
		sendToKitchen: 'Enviar órdenes directamente a la cocina',
		accessType: 'Selecciona el tipo de acceso para clientes',
		close: 'Cerrar',
		terms_condi_ruf: 'Términos y Condiciones de Ruf',
		responsability: 'Descargo de Responsabilidad:',
		responsabilityTxt1: `Los términos 'nosotros' o 'nuestro' se refieren a Ruf.Tenga en cuenta que nuestros Términos y Condiciones pueden cambiar sin previo aviso. Al utilizar nuestro sitio o realizar un pedido, usted acepta los términos a continuación. En la medida permitida por la ley, renunciamos a toda responsabilidad por cualquier inconveniente derivado del uso de nuestro sitio y sistema.`,
		changes: 'Cambios en Pedidos:',
		changesTxt1: `Si hay algún cambio en su pedido, le informaremos y procederemos a comunicarnos con la compañía para realizar un reembolso en caso de cancelación. No asumimos responsabilidad por cualquier daño derivado de esta práctica.`,
		cancelPolitics: 'Política de Cancelación:',
		cancelPoliticsTxt: `Si desea cancelar un pedido, notifíquenoslo inmediatamente. La cancelación queda a nuestro criterio y el de la compañía donde utilice esta plataforma. Si la comida aún no está preparada, generalmente permitiremos la cancelación y proporcionaremos un reembolso. Una vez que se ha realizado el pedido, no se podrá cancelar.`,
		alcohol: 'Servicio de Alcohol:',
		alcoholTxt: `Operamos como un lugar con licencia para la venta de alcohol y cumplimos todas las leyes locales al respecto. No servimos alcohol a menores de 18 años.`,
		payments: 'Pagos:',
		paymentsTxt: `Actualmente, no aceptamos pagos, pero cuando lo hagamos, se procesarán de forma segura.`,
		taxes: 'Impuestos:',
		taxesTxt: `Nuestros precios incluyen impuestos, variando según la compañía. La tasa suele ser del 8% en zonas fronterizas y del 16% en el interior de México.`,
		rights: 'Derechos de Autor:',
		rightsTxt: `Todas las imágenes y contenido del sitio tienen derechos de autor y licencia. No deben distribuirse, modificarse ni utilizarse sin nuestro consentimiento previo, que puede negarse a nuestra discreción. El sitio puede imprimirse, pero no modificarse ni utilizarse con fines comerciales.`,
		webSite: 'Sitio Web:',
		webSiteTxt: `Al utilizar nuestro sitio web, acepta nuestros términos y condiciones. Nos esforzamos por garantizar la precisión de la información, pero no asumimos responsabilidad por daños derivados de información incorrecta o problemas de rendimiento. Utilizamos contenido de terceros en nuestro sitio.`,
		privacy: 'Privacidad:',
		privacyTxt: `Su privacidad es fundamental. Solicitamos información solo en el registro de la plataforma o si usted asiste a compañías que utilizan nuestro servicio, el aplicativo comparte su información solo con la compañías que usted visita, pero no comparte sus datos con lugares donde no es utilizada nuestra plataforma, dicho esto usted tendrá el consentimiento sobre cómo funciona esta aplicación, puede estar seguro que cuidaremos y resguardaremos su información. En los casos donde el registro es por la parte de la o compañía no utilizaremos su información más que para comunicarnos con usted o hacerle saber información importante, pero no compartiremos su información con nadie.`,
		contacts: 'Contactos',
		contact: 'Contacto',
		offices: 'Oficinas',
		support: 'Soporte',
		sales: 'Ventas',
		admin: 'Administración',
		mail: 'Correos',
		webPage: 'Página web',
		productSelectedInStationFormScreen: 'El producto seleccionado será mostrado al escanear el código QR generado.',
		updateItemsMessage: (entities) => `Los ${entities} se actualizaron correctamente.`,
		stalls: 'Puestos',
		stall: 'Puesto',
		save: 'Guardar',
		cancel: 'Cancelar',
		new: 'Nuevo',
		cleanFilters: 'Limpiar Fltros',
		actions: 'Acciones',
		order: 'Orden',
		name: 'Nombre',
		phone: 'Teléfono',
		typeOfClientAccess: 'Tipo de acceso al cliente',
		delete: 'Borrar',
		typeOfAttention: 'Tipo de atención',
		placeHolder: (entity) => `Escribe ${entity[entity.length-1]=='a'?'la':'el'} ${entity?.toLowerCase()} aquí.`,
		assistant: 'Assistants',
		numAssistant: 'Número de asistentes',
		attachLogo: 'Adjuntar logo',
		attachCoverImage: 'Adjuntar imagen de portada',
		stations: 'Estaciones',
		importStations: 'Importar estaciones',
		code: 'Código',
		edit: 'Editar',
		exportToCSV: 'Exportar a CSV',
		printQRs: 'Imprimir códigos QR',
		importFile: 'Importar Archivo',
		exportFile: 'Exportar archivo',
		filterByErrors: 'Filtrar por errores',
		verify: 'Verificar',
		saveTheRecord: 'Por favor, guarde primero el registro',
		cannotBeEmpty: 'No puede ser vacío.',
		hideCompanyLabel: 'Ocultar súper compañía',
		hideCompanyInfo: 'Ocultar súper compañía en el menú de puestos',
		parameters: 'Parámetros',
		recommendedProduct: 'Producto recomendado',
		sendNotificationAfter: 'Enviar notificación después de... (HH:mm)',
		notificationSettings: 'Configuración de notificaciones',
		serviceRating: 'Calificación del servicio',
		recomendationInfo: 'Recomendar los siguientes productos cada vez que compre',
		notifyClient: 'Notificar cliente',
		accessVisible: "Menu de Accesos Visible",
		sendToAssistant: "Enviar órdenes al asistente",
		wirelessNetworkName: 'Nombre de la red inalámbrica',
		wirelessNetworkPassword: 'Contraseña de la red inalámbrica',
		downloadQrCode: 'Descargar código QR',
		products: 'Productos',
		seeProducts: 'Ver Productos',
		companies: 'Compañias',
		callAssistant: 'Llamar a asistente',
		statusOfOrder: 'Estatus de Orden',
		offersAndWallet: 'Ofertas y Monedero',
		scanQR: 'Escanear QR',
		contact: 'Contacto',
		listOfCompatibleCompanies: 'Lista de empresas compatibles',
		hello: 'Hola',
		guest: 'Invitado',
		pressTheButtonToScan: 'Presiona el botón para escanear',
		captureCode:'Captura el código del QR',
		search: 'Buscar',
		enterTheCode: 'Ingrese el código',
		companyName: 'Nombre de la compañía',
		stationName: 'Nombre de la estación',
		rate:"Calificar",
		skip:"Omitir",
		bad:"Malo",
		notGood:"Regular",
		good:"Bueno",
		veryGood:"Muy bueno",
		excellent:"Excelente",
		enableAppPermissions: 'Habilita los permisos de la app en Configuración de Clientes',
		loginSettings: 'Configuración de inicio de sesión',
		hideEmail: 'Ocultar correo electrónico',
		notProvided: 'No proporcionado',
		welcomeTo: 'Bienvenido a'
	},
}
