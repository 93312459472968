import crudHandlers from "./_crud";

const initialState = {
    entities: {},
    relWaitersById: null,
    assistances: [],
    allNotifications: 0,
    restroomNotifications: 0,
    billNotifications: 0,
    clientAtentionNotifications: 0,
    is_fetching: false,
    active: undefined,
    currentDayAssistances: [],
    currentWeekAssistances: [],
    monthlyAssistances: [],
    yearlyAssistances: [],
    averageResponseTime: null, // Tiempo promedio de respuesta general
    dailyPerformance: [], // Desempeño diario
    monthlyPerformance: [], // Desempeño mensual
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        // Asistencias generales
        case 'LOAD_ATTENTIONS':
            return {
                ...state,
                allNotifications: payload?.allNotifications ?? 0,
                restroomNotifications: payload?.restroomNotifications ?? 0,
                billNotifications: payload?.billNotifications ?? 0,
                clientAtentionNotifications: payload?.clientAtentionNotifications ?? 0,
                is_fetching: false,
            };

        // Cargar asistencias
        case 'LOAD_ASSISTANCES':
            return {
                ...state,
                assistances: payload,
            };

        // Hoy
        case 'SET_CURRENT_DAY_ASSISTANCES':
            return {
                ...state,
                currentDayAssistances: payload.notifications || [],
                averageResponseTime: payload.averageResponseTime || 0,
            };

        // Semana
        case 'SET_CURRENT_WEEK_ASSISTANCES':
            console.log(payload)
            return {
                ...state,
                currentWeekAssistances: payload.dailyPerformance || [],
                averageResponseTime: payload.averageDailyResponseTimes || [],
            };

        // Mes
        case 'SET_MONTHLY_ASSISTANCES':
            return {
                ...state,
                monthlyAssistances: {
                    performance: payload.monthlyPerformance || [], // Array de 12 meses
                    averageTimes: payload.averageMonthlyResponseTimes || [], // Array de tiempos promedio
                },
                totalMonthlyAssistances: payload.total || 0, // Total de asistencias
            };

        case 'SET_YEARLY_ASSISTANCES':
            return {
                ...state,
                yearlyAssistances: payload.result || [], // Array de objetos con year, totalAssistances, averageResponseTime
                totalYearlyAssistances: payload.total || 0, // Total de asistencias anuales
            };


        // Error al cargar asistencias
        case 'SET_ASSISTANCES_ERROR':
            return {
                ...state,
                is_fetching: false,
                error: payload,
            };

        // Manejo genérico para CRUD
        default:
            return crudHandlers(state, action, 'rel_waiter_table');
    }
};
