import { showSuccessMessage } from 'utils/messages'
import crud from './_crud'
import { callApi } from 'utils/api'
import Swal from 'sweetalert2'

const entity = 'client'
const actions = crud(entity, 'el cliente', 'los clientes')

function showSuccessMessageRedirect(message, props = {}) {
	Swal.fire({
		icon: 'success',
		text: message,
		showCancelButton: false,
		confirmButtonText: 'Ok',
		allowOutsideClick: false,
		...props,
	}).then((result) => {
		if (result.isConfirmed) {
			setTimeout(() => {
				console.log('Redirigiendo al usuario a /login')
				window.location.href = '/login'
			}, 1000)
		}
	})
}

const updateSync = (item) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/${entity}/utils/update_sync`, {
			method: 'PATCH',
			body: JSON.stringify(item),
			headers: {
				Authorization: state.app.user.token,
			},
			successMessage: `Se actualizó el cliente correctamente.`,
		})

		dispatch({
			type: `UPDATE_SYNC_${entity.toUpperCase()}`,
			response: res.body,
		})
	}
}

const addSync = (item) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/${entity}/utils/add_sync`, {
			method: 'POST',
			body: JSON.stringify(item),
			headers: {
				Authorization: state.app.user.token,
			},
			params: {
				will_create_user: item.will_create_user,
			},
			successMessage: `Se agregó el cliente correctamente.`,
		})

		dispatch({
			type: `ADD_SYNC_${entity.toUpperCase()}`,
			response: res.body,
		})
	}
}

export const registerClient = (obj) => {
	return async (dispatch, getState) => {
		let isClosed = false
		try {
			const res = await callApi('/client/create_client', {
				method: 'POST',
				body: JSON.stringify({ client: obj }),
			})
			if (res.status === 200) {
				showSuccessMessageRedirect('Se ha registrado correctamente')
				setTimeout(() => {
					window.location.href = '/client/company_qr_scanning'
				}, 3000)
			}
		} catch (e) {
			console.log(e)
		}
	}
}

export const externalLoginClient = (client, platform) => {
	return async (dispatch, getState) => {
		const state = getState();

		try {
			const res = await callApi('/client/external_login_client', {
				method: 'POST',
				body: JSON.stringify({ external_client: client, platform }),
			})

			if(res.status === 200) {
				localStorage.setItem('@external_token', JSON.stringify({ platform, token: res.body.token  }))
				dispatch({
					type: 'LOGIN_CLIENT',
					payload: res.body,
				})
			}
		} catch (error) {
			
		}
	}
}

const loadClients = (sys_company_id) => {
	return async (dispatch, getState) => {
		const state = getState()
		try {
			const res = await callApi(`/client/get_clients/${sys_company_id}`, {
				method: 'GET',
				headers: {
					Authorization: state.app.user.token,
				},
			})

			if(res.status == 200){
				dispatch({ type: 'LOAD_CLIENTS', response: res.body })
			}
		} catch (e) {
			console.log(e)
		}
	}
}

export const createSysCompany = (
	sysCompanyData,
	userData,
	tablesAndWaitersData
) => {
	return async (dispatch, getState) => {
		try {
			const response = await callApi(`/app/create_sys_company`, {
				method: 'POST',
				body: JSON.stringify({
					sysCompany: sysCompanyData,
					user: userData,
					tablesAndWaiters: tablesAndWaitersData,
				}),
			})
			if (response.status === 200) {
				showSuccessMessageRedirect('Se ha registrado correctamente')
				setTimeout(() => {
					console.log('Redirigiendo al usuario a /login')
					window.location.href = '/login'
				}, 3000)
			}
		} catch (error) {
			console.error(
				'Ha habido un problema con la operación de grabado: ',
				error
			)
		}
	}
}
const loadTableInfo = (tableInfo) => {
	return async (dispatch, getState) => {
		const state = getState()
		try {
			const res = await callApi(`/client/load_table_info`, {
				method: 'GET',
				params: tableInfo,
			})

			dispatch({
				type: 'TABLE_INFO',
				payload: res.body,
			})

			return res.body
		} catch (e) {
			console.log(e)
		}
	}
}

const deleteClient = (credentials) => {
	return async (dispatch, getState) => {
		const state = getState()
		try {
			const res = await callApi('/client/delete_client', {
				method: 'DELETE',
				body: JSON.stringify(credentials),
			})

			if (res.status === 200) {
				showSuccessMessage("Cliente eliminado con éxito");
				return res.body
			}
		} catch (e) {
			console.log(e)
		}
	}
}

export const sendNotificationToWaiters = ({ table_id, client_message, stall_id }) => {
	return async (dispatch, getState) => {
	  const state = getState();
	  try{
		const res = await callApi(
		  `/utils/send_notification_to_waiters/${table_id}`,
		  {
			method: "POST",
			headers: {
			  Authorization: state.app?.client?.token,
			},
			body: JSON.stringify({ client_message, stall_id }),
		  }
		);
		if (res.status === 200) {
		  console.log("Se ha registrado correctamente");
		  Swal.fire({
			icon:"success",
			title:"Solicitud enviada ✔️",
			text:"Tu solicitud se envió correctamente."
		  });
	
		  return res.body
		}
	  }catch(e){
	  }
	};
  };

let customActions = {}
customActions = { ...customActions, updateSync }
customActions = { ...customActions, addSync }

export default { ...actions, ...customActions, loadClients, loadTableInfo, deleteClient }
