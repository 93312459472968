const initialState = {
	lang: 'es',
	recoveryEmail: '',
	recovertPass: {},// typeUser, email
}
export default (state = initialState, action) => {
	const { type, payload } = action
	switch (type) {
		case 'LOGIN': {
			return {
				...state,
				user: { ...payload },
			}
		}
		case 'LOGOUT': {
			return {
				...state,
				user: null,
				superAdmin: null,
				client: null,
			}
		}
		case 'UPDATE_CLIENT_PERMISSIONS': {
			const user = state.user ? {...state.user} : null
			
			if(user){
				user.company.contact_permits = payload.contact_permits
			}

			return {
				...state,
				user
			}
		}
		case 'LOGIN_CLIENT': {
			return {
				...state,
				client: { ...payload.client },
			}
		}
		case 'CLIENT_LOGOUT': {
			return {
				...state,
				client: null,
			}
		}
		case 'SUPER_ADMIN_LOGIN': {
			return {
				...state,
				superAdmin: payload,
			}
		}
		case 'SET_LANGUAGE': {
			return {
				...state, 
				lang: payload
			}
		}
		case 'SET_RECOVERY_EMAIL': {
			return {
				...state,
				recoveryEmail: payload,
				recovertPass: payload,
			}
		}
		default:
			return state
	}
}
