import React, { Suspense, lazy, useEffect, useState } from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import PublicRoute from 'components/Routes/PublicRoute'
import PublicClientRoute from 'components/Routes/PublicClientRoute'
import PublicClientRegisterRoute from 'components/Routes/PublicClientRegisterRoute'
import PublicDeleteAccountRoute from 'components/Routes/PublicDeleteAccountRoute'
import PrivateRoute from 'components/Routes/PrivateRoute'
import NewPublicClientRoute from 'components/Routes/NewPublicClientRoute'
import PrivateClientRoute from 'components/Routes/PrivateClientRoute'
import PrivateSuperAdminRoute from 'components/Routes/PrivateSuperAdminRoute'
import ClientRegister from './register/ClientRegister'
import ChangePassword from './login/ChangePassword'
import PasswordRecovery from './login/PasswordRecovery'
import AccountRegister from './register/AccountRegister'
import getUrlParams from '../utils/getUrlParams'

import {
	loginWithToken,
	clientLoginWithToken,
	driverLoginWithToken,
	loginWithExternalToken,
	setLanguage,
} from 'store/actions/app'
import { loginSuperAdmin } from '../store/actions/app'
import { Dimmer, Loader, Segment } from 'semantic-ui-react'

const HomePage = lazy(() => import('./home/HomePage'))
const LoginRoute = lazy(() => import('./login/index'))
const RolePage = lazy(() => import('./settings/RolePage'))
const RoleIndividualPage = lazy(() => import('./settings/RoleIndividualPage'))
const ConsoleSuperAdmin = lazy(() => import('./login/ConsoleSuperAdmin'))
const ClientLogin = lazy(() => import('./login/ClientLogin'))
const DeleteAccountPage = lazy(() => import('./delete_account/DeleteAccountPage'))
const CompanyQRScanningsPage = lazy(() => import('./ClientSite/CompanyQRScanningsPage'))
const SysCompanyAdministrator = lazy(() => import('./login/SysCompanyAdministrator'))

const Routers = (props) => {
	const [isTryingToLogin, setIsTryingToLogin] = useState(true)

	const tryToLogin = async () => {
		// TODO: CHECAR EL INICIO DE SESIÓN AUTOMÁTICO DE FACEBOOK
		const external_object = JSON.parse(localStorage.getItem('@external_token'))
		if (external_object) {
			const { platform, token } = external_object
			if (platform === 'facebook') {
				props.loginWithFacebookToken(token)
			} else if (platform === 'google') {
				props.loginWithGoogleToken(token)
			}
		} else {
			const token = localStorage.getItem('@token')
			const client_token = localStorage.getItem('@client_token')
			const employee_code = localStorage.getItem('@employee_code')
			const superAdminHeaders = localStorage.getItem('@superAdmin_headers')

			if (token) {
				if (employee_code) {
					await props.loginWithToken({ token, employee_code })
				} else {
					await props.loginWithToken({ token })
				}
			}
			if (client_token) {
				await props.clientLoginWithToken({ client_token })
			}
			if(superAdminHeaders){
				const headers = await JSON.parse(superAdminHeaders)
				props.loginSuperAdmin(headers, false)
			}
		}

		setIsTryingToLogin(false)
	}
	useEffect(() => {
		tryToLogin()
	}, [])

	//Sets language by search params
	useEffect(()=>{
		const params = getUrlParams(document.URL)
		if(params.lang === 'es' || params.lang === 'en'){
			props.setLanguage(params.lang)
		}
	}, [document.URL])

	if (isTryingToLogin) {
		return (
			<Dimmer active inverted className='padding-0'>
				<Loader inverted>Cargando</Loader>
			</Dimmer>
		)
	}

	const modulesToRender = props.user?.modules?.filter((mod) => {
		const { permisitions } = mod

		return permisitions?.ACCESS
	})

	return (
		<React.Fragment>
			<BrowserRouter>
				<Suspense
					fallback={
						<Segment className='width-100 height-100 padding-0 border-none margin-0 border-radius-0'>
							<Dimmer active inverted className='padding-0'>
								<Loader inverted>Cargando página</Loader>
							</Dimmer>
						</Segment>
					}
				>
					<Switch>
						<ChangePassword
							path='/change_password'
							component={ChangePassword}
							exact={true}
						/>

						<PasswordRecovery
							path='/recovery_password'
							component={PasswordRecovery}
							exact={true}
						/>

						<AccountRegister
							path='/account_register'
							component={AccountRegister}
							exact={true}
						/>

						<PublicDeleteAccountRoute
							path='/delete_account'
							component={DeleteAccountPage}
							exact={true}
						/>

						<PublicRoute path='/login' component={LoginRoute} exact={true} />
						<PrivateRoute path='/' component={HomePage} exact={true} />
						<PrivateRoute
							path='/settings/role'
							component={RolePage}
							exact={true}
						/>
						<PrivateRoute
							path='/settings/role/:id'
							component={RoleIndividualPage}
							exact={true}
						/>

						{props.isAuthenticated && (
							<>
								{modulesToRender?.map((m) => {
									const ComponentPage = lazy(() => import(`${m.folder}`))

									if (ComponentPage)
										return (
											<PrivateRoute
												path={`/${m.url}`}
												component={ComponentPage}
												title={m.name}
												exact={true}
												key={m.url}
											/>
										)
								})}
							</>
						)}

						{/* Superadmin */}
						<PublicRoute
							path='/login_superadmin'
							component={ConsoleSuperAdmin}
							exact={true}
						/>

						<PrivateSuperAdminRoute
							path='/sys_company_administrator'
							component={SysCompanyAdministrator}
							exact={true}
						/>

						{/* Client */}
						<PublicClientRoute
							path='/login_client'
							component={ClientLogin}
							exact={true}
						/>
						
						<NewPublicClientRoute
							path='/client/company_qr_scanning'
							component={CompanyQRScanningsPage}
							exact={true}
						/>

						<PublicRoute
							path='/*'
							component={LoginRoute}
							// exact={true}
						/>
					</Switch>
				</Suspense>
			</BrowserRouter>
		</React.Fragment>
	)
}

const mapDispatchToProps = (dispatch) => ({
	loginWithToken: (parmas) => dispatch(loginWithToken(parmas)),
	clientLoginWithToken: (params) => dispatch(clientLoginWithToken(params)),
	loginWithFacebookToken: (token) =>
		dispatch(loginWithExternalToken(token, 'facebook')),
	loginWithGoogleToken: (token) =>
		dispatch(loginWithExternalToken(token, 'google')),
	loginSuperAdmin: (headers) => dispatch(loginSuperAdmin(headers)),
	setLanguage: (lang) => dispatch(setLanguage(lang)),
})

const mapStateToProps = (state) => ({
	user: state.app.user,
	isAuthenticated: !!state.app.user,
	isClientAuthenticated: !!state.app.client,
	isSuperAdmindAuthenticated: state.app?.superAdmin,
})

export default connect(mapStateToProps, mapDispatchToProps)(Routers)
