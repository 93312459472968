import React, { useState, useEffect } from 'react'
import { Button, Card, Segment, Grid, Image, Loader } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { Field, Form, Formik } from 'formik'
import Input from 'components/InputForm'
import { useDispatch, connect } from 'react-redux'
import { recoveryPassword, recoveryPasswordUser } from 'store/actions/app'
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

import LogoImg from '../../images/logo1024.jpg'
import { showErrorMessage } from 'utils/messages'
function validate(email) {}
const PasswordRecovery = (props) => {
	const [sent, setSent] = useState(null)
	const [sending, setSending] = useState(false)
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const typeUser = queryParams.get('typeUser');
	const dispatch = useDispatch()

	const onSubmit = async (values, { setSubmitting }) => {
		//Evento para mandar API
		const mail = String(values?.email).trim()
		if (mail.length > 0) {
			const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
			if (emailRegex.test(mail)) {
				setSending(true)
				const obj = {
					email: values.email,
				}
				if (typeUser == 0){
					await dispatch(recoveryPassword(obj.email))
					setSent(0);
				} else if (typeUser == 1) {
					await dispatch(recoveryPasswordUser(obj.email))
					setSent(1);
				}
				setSending(false)
			} else {
				showErrorMessage({error: 'Introduce un correo electrónico válido'})
			}
		}
	}

	return (
		sent === null  ?(
		<div
			style={{
				width: '100wh',
				height: '100vh',
				justifyContent: 'center',
				alignItems: 'center',
				padding: '20px',
			}}
			className='login-background'
		>
			<div
				style={{
					display: 'flex',
					height: '20vh',
					position: 'fixed',
					top: 0,
					left: 0,
				}}
			></div>
			<Grid centered>
				<Grid.Row>
					<Grid.Column computer={6} tablet={8} mobile={16}>
						<Segment>
							<Grid stackable>
								<Grid.Row
									style={{
										alignItems: 'center',
										justifyContent: 'center',
									}}
									textAlign='center'
								>
									<div
										onClick={() => {
											window.location.href = '/login'
										}}
										style={{ cursor: 'pointer' }}
									>
										<Image
											src={process.env.REACT_APP_LOGO_LOGIN_URL ?? LogoImg}
											size='medium'
										/>
									</div>
								</Grid.Row>
								<Grid.Row
									style={{
										alignItems: 'center',
										justifyContent: 'center',
									}}
									textAlign='center'
								>
									<Formik
										initialValues={{email: typeof props.recoveryEmail == 'string' ? props?.recoveryEmail : ''}}
										onSubmit={onSubmit}
									>
										{({ isSubmitting }) => (
											<Form className='ui form'>
												<Card>
													<Card.Content>
														<Card.Header textAlign='center'>
															Sitio del Cliente
														</Card.Header>
														<Card.Description>
															Recuperación de contraseña
														</Card.Description>
													</Card.Content>
													<Card.Content>
														<Field
															type='email'
															name='email'
															component={Input}
															label='Correo electrónico'
														/>
														<Button
															style={{
																margin: '1rem 0',
																width: '100%',
															}}
															className='general-color'
															type='submit'
															content={
																<>
																	{sending ? (
																		<Loader
																			size='mini'
																			active
																			inline='centered'
																		>
																			Enviando
																		</Loader>
																	) : (
																		'Enviar'
																	)}
																</>
															}
														/>
													</Card.Content>
												</Card>
											</Form>
										)}
									</Formik>
								</Grid.Row>
							</Grid>
						</Segment>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</div>) : (
			sent == 0 ? <Redirect to='/login_client' /> :<Redirect to='/login' />
		)
	)
}
const mapDispatchToProps = (dispatch) => ({
	recoveryPassword: (email) => dispatch(recoveryPassword(email)),
	recoveryPasswordUser: (email) => dispatch(recoveryPasswordUser(email)),
})

const mapStateToProps = (state) => ({
	recoveryEmail: state.app?.recovertPass.email,
	recoveryTypeUser: state.app?.recovertPass.typeUser,
})

export default connect(mapStateToProps, mapDispatchToProps)(PasswordRecovery)
